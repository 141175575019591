import React, { Component } from "react";
import styles from "./styles/rank.module.css";
import Loader from "./loader";

class Rank extends Component {
    render() {
        return (
            <div className={styles.wrapper}>
                {this.props.data.error ||
                this.props.data.keywordRank.keywordRank === "ERROR" ? (
                    <div className="error">{this.props.error()}</div>
                ) : (
                    <div>
                        <div className={styles.top}>
                            <label className={styles.label}>
                                Google Rank:{" "}
                            </label>

                            {this.props.data.isLoaded ? (
                                <label className={styles.rank}>56</label>
                            ) : (
                                <label className={styles.loaderLabel}>
                                    <div className={styles.loader}></div>
                                </label>
                            )}
                        </div>
                        <div className={styles.bottom}>
                            <span>* Avg rank in USA</span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
    get(url) {
        //url ex. /getbasicmeta or /getsocialmeta
        return new Promise((resolve, reject) => {
            console.log("get(): " + url);
            fetch(
                "http://ec2-52-53-250-223.us-west-1.compute.amazonaws.com:9000" +
                    url
            )
                .then(res => res.json())
                .then(json => {
                    resolve(json);
                });
        });
    }
}

export default Rank;
