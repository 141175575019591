import React, { Component } from "react";
import styles from "./styles/elementTable.module.css";
import TextareaAutosize from "react-textarea-autosize";
import { updateElement } from "./../sheet";

class ElementTable extends Component {
    state = {
        type: "<" + this.props.data.type + ">",
        text: this.props.data.text,
        target: this.props.target,
        edit: false,
        edited: false,
        newText: this.props.data.newText
    };
    handleInputChange = event => {
        event.preventDefault();
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                updateElement(this.props.data, this.state.newText);
            }
        );
        //this.props.updateElement(this.props.data, this.state.newText);
    };
    highlight(text) {
        var targetFound = false;
        var keyword = "";
        var regexp = new RegExp(this.state.target, "gi");
        var split = text.split(regexp);
        if (split.length === 1) targetFound = false;
        else {
            targetFound = true;

            keyword = text.replace(split[0], "");
            keyword = keyword.replace(split[1], "");
        }
        return (
            <span>
                {targetFound ? (
                    <span>
                        {split[0]}
                        <strong>{keyword}</strong>
                        {split[1]}
                    </span>
                ) : (
                    <span>{split[0]}</span>
                )}
            </span>
        );
    }
    render() {
        return (
            <tr className={styles.tr}>
                <td className={styles.tdLeft}>
                    {this.state.type} {this.highlight(this.state.text)}{" "}
                </td>
                <td className={styles.tdRight}>
                    <TextareaAutosize
                        placeholder={this.state.type}
                        className={styles.input}
                        name="newText"
                        value={this.state.newText}
                        onChange={this.handleInputChange}
                    ></TextareaAutosize>
                </td>
            </tr>
        );
    }
}
export default ElementTable;
