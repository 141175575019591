import React, { Component } from "react";
import styles from "./styles/editorTable.module.css";
import ElementTable from "./elementTable";
import Loader from "./loader";
class EditorTable extends Component {
    state = {
        elements: []
    };
    render() {
        return (
            <div className="container">
                {this.props.data.isLoaded && this.props.input.isLoaded ? (
                    <table className={styles.table}>
                        <tbody>
                            {this.props.data.tree.tree.map(element => (
                                <ElementTable
                                    key={element.text}
                                    data={element}
                                    target={this.props.input.input.keyword}
                                />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                )}
            </div>
        );
    }
}

export default EditorTable;
