import React, { Component } from "react";
import TopSection from "./topSection";
import BottomSection from "./bottomSection";

class Sections extends Component {
    state = {
        input: {
            url: "",
            keyword: "",
            country: "",
            city: ""
        },
        showBottom: false
    };
    async modifyInput(newInput) {
        this.setState({
            input: {
                url: newInput.url,
                keyword: newInput.keyword,
                country: newInput.country,
                city: newInput.city
            }
        });
    }
    changeValid(newValid) {
        // this.setState({
        //     valid: newValid
        // })
        if (newValid)
            this.setState({
                showBottom: true
            });
    }

    render() {
        return (
            <div className="container">
                <TopSection
                    overallInput={this.modifyInput.bind(this)}
                    valid={this.changeValid.bind(this)}
                />
                {this.state.showBottom ? (
                    <BottomSection input={this.state.input} />
                ) : null}
            </div>
        );
    }
}

export default Sections;
