import React, { Component } from "react";
import styles from "./styles/bar.module.css";

class Bar extends Component {
    state = {
        count: 0
    };
    render() {
        return (
            <div className={styles.wrapper}>
                <span>
                    <strong>Tweakkt.com</strong>
                </span>
                <span className={styles.authentication}>
                    <strong>Login | Logout</strong>
                </span>
            </div>
        );
    }
}

export default Bar;
