import React, { Component } from "react";
import styles from "./styles/rankNew.module.css";
import Loader from "./loader";

class Rank extends Component {
    componentDidUpdate() {
        console.log(this.props);
    }
    render() {
        return (
            <div className={styles.wrapper}>
                {this.props.data.error ||
                this.props.data.keywordRank.keywordRank === "ERROR" ? (
                    <div className="error">{this.props.error()}</div>
                ) : (
                    <div>
                        <div className={styles.top}>
                            <div className={styles.labelWrapper}>
                                <span className={styles.label}>
                                    Google Rank:{" "}
                                </span>
                            </div>
                            {this.props.data.isLoaded ? (
                                <div className={styles.rankWrapper}>
                                    <span className={styles.rank}>
                                        {
                                            this.props.data.keywordRank
                                                .keywordRank.rank
                                        }
                                    </span>
                                </div>
                            ) : (
                                <div className={styles.loaderWrapper}>
                                    <span className={styles.loader} />
                                </div>
                            )}
                        </div>
                        <div className={styles.bottom}>
                            <span>* Avg rank in USA</span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Rank;
