import React, { Component } from "react";
import styles from "./styles/loader.module.css";

class Loader extends Component {
    render() {
        return <div className={styles.loader}></div>;
    }
}

export default Loader;
