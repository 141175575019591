import React, { Component } from "react";
import styles from "./styles/checklist.module.css";
import Loader from "./loader";

class Checklist extends Component {
    state = {};
    render() {
        return (
            <div className={styles.wrapper}>
                {this.props.data.isLoaded ? (
                    <table className={styles.table}>
                        <tbody>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.title.valid ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>Title exists</td>
                            </tr>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.desc.valid ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>
                                    Description exists
                                </td>
                            </tr>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.h1.valid ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>
                                    H1 (exactly one) exists
                                </td>
                            </tr>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.title
                                    .targetExists ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>
                                    Target appears in Title
                                </td>
                            </tr>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.desc.targetExists ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>
                                    Target appears in Description
                                </td>
                            </tr>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.h1.targetExists ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>
                                    Target appears in H1
                                </td>
                            </tr>
                            <tr className={styles.tr}>
                                {this.props.data.checklist.h2.targetExists ? (
                                    <td className={styles.yes}>
                                        <span className="fa fa-check"></span>
                                    </td>
                                ) : (
                                    <td className={styles.no}>
                                        <span className="fa fa-times"></span>
                                    </td>
                                )}
                                <td className={styles.right}>
                                    Target appears in H2
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <Loader />
                )}
            </div>
        );
    }
}

export default Checklist;
