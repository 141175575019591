import React, { Component } from "react";
import Input from "./input";
import Bar from "./bar";
import Current from "./current";
import styles from "./styles/topSection.module.css";

class TopSection extends Component {
    state = {
        url: "",
        keyword: "",
        country: "",
        city: "",
        submitted: false,
        valid: true
    };
    changeInput(newInput) {
        this.setState({
            url: newInput.url,
            keyword: newInput.keyword,
            country: newInput.country,
            city: newInput.city,
            submitted: newInput.submitted
        });
        this.props.overallInput(newInput);
    }
    changeValid(newValid) {
        var v = false;
        if (newValid.siteIsValid && newValid.locationIsValid) v = true;
        this.setState({
            valid: v
        });
        this.props.valid(this.state.valid);
    }
    render() {
        return (
            <div className="container">
                <Bar />
                <div className={styles.input}>
                    <Input input={this.changeInput.bind(this)} />
                </div>
                {this.state.submitted ? (
                    <div className={styles.current}>
                        <Current
                            input={this.state}
                            valid={this.changeValid.bind(this)}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </div>
        );
    }
}

export default TopSection;
