import React, { Component } from "react";
import Checklist from "./checklist";
import Rank from "./rank";
import RankNew from "./rankNew";
import EditorTable from "./editorTable";
import { post } from "../request";
import { get } from "../request";
const request = require("../request");
const sheet = require("./../sheet");

class BottomSection extends Component {
    state = {
        basicMeta: {
            isLoaded: false,
            basicMeta: {},
            error: false
        },
        socialMeta: {
            isLoaded: false,
            socialMeta: {},
            error: false
        },
        keywordRank: {
            isLoaded: false,
            keywordRank: {},
            error: false
        },
        checklist: {
            isLoaded: false,
            checklist: {},
            error: false
        },
        tree: {
            isLoaded: false,
            tree: {},
            error: false
        },
        date: {
            isLoaded: false,
            date: {},
            error: false
        },
        input: {
            isLoaded: false,
            input: {},
            error: false
        }
    };
    resetState() {
        this.setState({
            basicMeta: {
                isLoaded: false,
                basicMeta: {},
                error: false
            },
            socialMeta: {
                isLoaded: false,
                socialMeta: {},
                error: false
            },
            keywordRank: {
                isLoaded: false,
                keywordRank: {},
                error: false
            },
            checklist: {
                isLoaded: false,
                checklist: {},
                error: false
            },
            tree: {
                isLoaded: false,
                tree: {},
                error: false
            },
            date: {
                isLoaded: false,
                date: {},
                error: false
            },
            input: {
                isLoaded: false,
                input: {},
                error: false
            }
        });
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <EditorTable
                            data={this.state.tree}
                            input={this.state.input}
                            error={this.componentError}
                        />
                        <br />
                        {/* <MetaBasic
                            data={this.state.basicMeta}
                            error={this.componentError}
                        />
                        <br />
                        <MetaSocial
                            data={this.state.socialMeta}
                            error={this.componentError}
                        /> */}
                    </div>
                    <div className="col-4">
                        {/* <Rank
                            data={this.state.keywordRank}
                            error={this.componentError}
                        /> */}
                        <RankNew
                            data={this.state.keywordRank}
                            error={this.componentError}
                        />
                        <br />
                        <Checklist
                            data={this.state.checklist}
                            error={this.componentError}
                        />
                        <br />
                    </div>
                </div>
                <br></br>
                <div className="row"></div>
            </div>
        );
    }
    getLocal(endpoint, name) {
        return new Promise((resolve, reject) => {
            get(endpoint)
                .then(data => {
                    this.setState({
                        [name]: {
                            isLoaded: true,
                            [name]: data,
                            error: false
                        }
                    });
                    resolve(data);
                })
                .catch(err => {
                    this.setState({
                        [name]: {
                            isLoaded: true,
                            error: true
                        }
                    });
                    reject(err);
                });
        });
    }
    componentError() {
        return (
            <p className="text-danger">
                <strong>ERROR: COULD NOT RETRIEVE</strong>
            </p>
        );
    }
    async componentDidMount() {
        this.getLocal("/getbasicmeta", "basicMeta");
        this.getLocal("/getsocialmeta", "socialMeta");
        this.getLocal("/getchecklist", "checklist");
        this.getLocal("/getheadertree", "tree").then(data =>
            sheet.pushElements(data.tree)
        );
        this.getLocal("/getdate", "data");
        this.getLocal("/getinput", "input");
        this.getLocal("/getkeywordranking", "keywordRank");
    }
}

export default BottomSection;
