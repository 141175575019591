import React, { Component } from "react";
import styles from "./styles/current.module.css";
import Loader from "./loader";
import { post } from "../request";
import { get } from "../request";
const sheet = require("./../sheet");

class Current extends Component {
    state = {
        show: false,
        date: {
            isLoaded: false,
            date: {}
        },
        valid: {},
        isLoaded: false,
        time: {}
    };
    async componentDidMount() {
        var valid = await post("/", this.props.input);
        this.setState({
            valid: {
                loaded: true,
                siteIsValid: valid.siteIsValid,
                locationIsValid: valid.locationIsValid
            }
        });
        this.props.valid(this.state.valid);
        var date = await get("/getdate");
        this.setState({
            date: {
                isLoaded: true,
                date: date
            },
            isLoaded: true,
            time: this.getTime()
        });
    }
    createSheet() {
        var header = {
            url: this.props.input.url,
            keyword: this.props.input.keyword,
            country: this.props.input.country,
            city: "",
            time: this.getTime()
        };
        sheet.createSheet(header);
    }
    getTime() {
        var d = new Date();
        var rawTime = {
            hours: d.getHours(),
            minutes: d.getMinutes()
        };
        var fancyTime = {};
        if (rawTime.hours > 12) {
            fancyTime = {
                hours: rawTime.hours - 12,
                minutes: rawTime.minutes,
                modifier: "pm"
            };
        } else if (rawTime.hours === 0) {
            fancyTime = {
                hours: 12,
                minutes: rawTime.minutes,
                modifier: "am"
            };
        } else {
            fancyTime = {
                hours: rawTime.hours,
                minutes: rawTime.minutes,
                modifier: "am"
            };
        }
        if (fancyTime.minutes / 10 < 1)
            fancyTime.minutes = "0" + fancyTime.minutes;
        return fancyTime;
    }
    render() {
        return (
            <div className={styles.wrapper}>
                {this.state.valid.loaded ? (
                    <div>
                        <div className={styles.current}>
                            <div className={styles.labels}>
                                <label className={styles.label}>URL:</label>
                                <label className={styles.label}>target:</label>
                                <label className={styles.label}>date:</label>
                            </div>
                            <div className={styles.texts}>
                                {/* <label className={styles.url}>
                            https://solarisbiotechusa.com/
                        </label> */}
                                {this.state.valid.siteIsValid ? (
                                    <label className={styles.url}>
                                        <a
                                            className={styles.a}
                                            href={this.props.input.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {this.props.input.url}
                                        </a>
                                    </label>
                                ) : (
                                    <label className={styles.errorText}>
                                        NOT VALID
                                    </label>
                                )}
                                <label className={styles.text}>
                                    {this.props.input.keyword}
                                </label>
                                {this.state.date.isLoaded ? (
                                    <label className={styles.text}>
                                        {this.state.date.date.month}{" "}
                                        {this.state.date.date.day},{" "}
                                        {this.state.date.date.year} -{" "}
                                        {this.state.time.hours}:
                                        {this.state.time.minutes}{" "}
                                        {this.state.time.modifier}
                                    </label>
                                ) : (
                                    <label>
                                        <Loader />
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button
                                className={styles.button}
                                onClick={() => this.createSheet()}
                            >
                                Save
                            </button>
                            <button disabled className={styles.buttonInactive}>
                                Send
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                )}
            </div>
        );
    }
}

export default Current;
