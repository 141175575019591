import React, { Component } from "react";
import styles from "./styles/input.module.css";

class Input extends Component {
    state = {
        url: "",
        keyword: "",
        country: "United States",
        city: "",
        submitted: false,
        inputClass: "form-control"
    };
    handleSubmit = event => {
        event.preventDefault();
        if (this.state.url !== "" && this.state.keyword !== "") {
            this.sendInput();
        }
    };
    async sendInput() {
        await this.setState({
            submitted: true
        });
        await this.props.input(this.state);
        this.setState({
            url: "",
            keyword: ""
        });
    }
    handleInputChange = event => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    render() {
        return (
            <div className={styles.wrapper}>
                <form onSubmit={this.handleSubmit}>
                    <div className={styles.left}>
                        <div className={styles.group}>
                            <label className={styles.label}>URL:</label>
                            <input
                                className={styles.input}
                                name="url"
                                value={this.state.url}
                                onChange={this.handleInputChange}
                            ></input>
                        </div>
                        <div className={styles.group}>
                            <label className={styles.label}>Target:</label>
                            <input
                                className={styles.input}
                                name="keyword"
                                value={this.state.keyword}
                                onChange={this.handleInputChange}
                            ></input>
                        </div>
                    </div>
                    <div className={styles.right}>
                        {this.state.submitted ? (
                            <button disabled className={styles.buttonInactive}>
                                Tweakk It
                            </button>
                        ) : (
                            <button type="submit" className={styles.button}>
                                Tweakk It
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default Input;
