var dns = "54.193.159.161";
//var dns = "ec2-54-193-159-161.us-west-1.compute.amazonaws.com";

export function get(url) {
    return new Promise(async (resolve, reject) => {
        fetch(await fullUrl(url))
            .then((res) => res.json())
            .then((json) => {
                resolve(json);
            })
            .catch((err) => reject(err));
        // resolve(testObject[url]); //FOR TESTING - UNCOMMMENT ABOVE FOR ACTUAL FUNCTIONALLITY
    });
}

export function post(url, postObj) {
    return new Promise(async (resolve, reject) => {
        fetch(await fullUrl(url), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postObj),
        })
            .then(async (res) => {
                var response = await res.json();
                resolve(response);
            })
            .catch((err) => reject(err));
    });
}

export function fullUrl(url) {
    return new Promise((resolve, reject) => {
        var proxyUrl = "https://cors-anywhere.herokuapp.com/";
        resolve(proxyUrl + "http://" + dns + ":9000" + url);
    });
}

export function error(e) {
    console.log("request failed: " + e);
}

// const testObject = {
//     "/getbasicmeta": {
//         title:
//             "Solaris Biotech USA | Bioreactors, Fermentors, Bioprocessing Equipment",
//         desc:
//             "Solaris manufactures specialized bioprocessing equipment for biotech, pharmaceutical, food, beverage, + dairy industries. Visit our site for full line of fermentors + bioreactors.",
//         canonical: "https://solarisbiotechusa.com/"
//     },
//     "/getsocialmeta": {
//         "og:site_name": "Solaris Biotech USA",
//         "og:type": "website",
//         "og:url": "https://solarisbiotechusa.com/",
//         "og:title":
//             "Solaris Biotech USA | Bioreactors, Fermentors, Bioprocessing Equipment",
//         "og:description":
//             "Solaris manufactures specialized bioprocessing equipment for the biotech, pharmaceutical, food, beverage, and dairy industries. Visit our site for full line of fermentors and bioreactors.",
//         "twitter:card": "summary",
//         "twitter:url": "https://solarisbiotechusa.com/",
//         "twitter:title": "Solaris Biotech USA"
//     },
//     "/getchecklist": {
//         title: {
//             valid: true,
//             occurences: 1,
//             targetExists: true
//         },
//         desc: {
//             valid: true,
//             occurences: 1,
//             targetExists: true
//         },
//         h1: {
//             valid: true,
//             occurences: 1,
//             targetExists: true
//         },
//         h2: {
//             valid: true,
//             occurences: 0,
//             targetExists: false
//         }
//     },
//     "/getheadertree": {
//         tree: [
//             {
//                 type: "title",
//                 text:
//                     "Solaris Biotech USA | Bioreactors, Fermentors, Bioprocessing Equipment",
//                 newText: "",
//                 tab: "0"
//             },
//             {
//                 type: "desc",
//                 text:
//                     "Solaris manufactures specialized bioprocessing equipment for biotech, pharmaceutical, food, beverage, + dairy industries. Visit our site for full line of fermentors + bioreactors.",
//                 newText: "",
//                 tab: "0"
//             },
//             { type: "p", text: "925.407.2090", newText: "", tab: "0" },
//             {
//                 type: "h1",
//                 text:
//                     "Solaris Biotech USA: Bioreactors. Fermentors. Processing Equipment.",
//                 newText: "",
//                 tab: "0"
//             },
//             {
//                 type: "h2",
//                 text: "Cell Culture + Fermentation Solutions for ",
//                 newText: "",
//                 tab: "1"
//             },
//             { type: "h3", text: "Lab Scale", newText: "", tab: "2" },
//             {
//                 type: "p",
//                 text:
//                     "Benchtop Bioreactors + Fermentors are flexible and user-friendly for life sciences product development.",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text:
//                     "Solaris provides a full range of glass vessels, stainless steel, photobioreactors, and single use ranging 200 ML to 10 L.",
//                 newText: "",
//                 tab: "3"
//             },
//             { type: "h3", text: "Pilot Plant", newText: "", tab: "2" },
//             {
//                 type: "p",
//                 text: "Designed to simplify the scaling up process.",
//                 newText: "",
//                 tab: "3"
//             },
//             { type: "h3", text: "Industrial Scale", newText: "", tab: "2" },
//             {
//                 type: "p",
//                 text: "Large scale fermentation and bioprocessing solutions.",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text:
//                     "Single Use Bioreactors (SUBs) are now being widely adopted because they eliminate the need for cleaning, which reduces downtime and makes the cGMP process easier.",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text: "Advantages of single use >>",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text:
//                     "TFF  is an important step in bioprocessing but has its unique set of challenges. How can TFF be more reliable and better optimized for process-driven systems?",
//                 newText: "",
//                 tab: "3"
//             },
//             { type: "p", text: "Why use TFF?", newText: "", tab: "3" },
//             {
//                 type: "p",
//                 text:
//                     "Photobioreactors allow for exquisite control of your phototrophic bioprocess to optimize photosynthetic efficiency, biomass production, and high value products.",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text: "Learn more about photobioreactors >>",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text:
//                     "Copyright © 2020, Solaris Biotech USA | Solaris Biotech Europe",
//                 newText: "",
//                 tab: "3"
//             },
//             {
//                 type: "p",
//                 text: " Web design by  Superclean",
//                 newText: "",
//                 tab: "3"
//             }
//         ]
//     },
//     "/getinput": {
//         url: "https://solarisbiotechusa.com/",
//         keyword: "bioreactor",
//         country: "United States",
//         city: ""
//     },
//     "/getkeywordranking": { keywordRank: "92" },
//     "/getdate": {
//         year: 2020,
//         monthNum: 2,
//         month: "February",
//         day: 3
//     }
// };

// export default {
//     get: get,
//     post: post,
//     fullUrl: fullUrl,
//     error: error
// };
