const request = require("./request");
var elements = [];

export function pushElements(elementsToPush) {
    elementsToPush.forEach(elementToPush => {
        elements.push(elementToPush);
    });
}

export function updateElement(elementToUpdate, newText) {
    elements.forEach(element => {
        if (
            element.type === elementToUpdate.type &&
            element.text === elementToUpdate.text
        ) {
            element.newText = newText;
        }
    });
}

export async function createSheet(header) {
    var postObj = {
        header: header,
        content: elements
    };
    await request.post("/sendsheetdata", postObj);
    window.open(await request.fullUrl("/getsheet"));
}
